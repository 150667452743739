<template>
  <div>
    <Card class="py-3">
        Configs
    </Card>
  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>